<!--
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-09-10 09:43:36
 * @LastEditTime: 2019-09-29 12:38:21
 * @LastEditors: Please set LastEditors
 -->
<template>
<!--  animated bounceInRight -->
  <div class="enterprise">
    <zongqiu class="xuanfu" v-drag></zongqiu>
    <!-- <heade></heade> -->
    <!-- <header class="header">
      <div class="center_title">
        <div class="header_left" @click="home">
          <img class="logo" src="../assets/logo.png" alt>
          <img class="text" src="../assets/text2.png" alt>
        </div>
        <div class="header_right">
          <img class="phone" src="../assets/phone.png" alt>
          <p class="phone_text">公司电话 1008611</p>
        </div>
      </div>
    </header> -->
    <div class="box">
      <!-- <div class="beijing"></div> -->
      <p>企业登录</p>
      <input style="margin-top:0.2rem;" type="text" placeholder="请输入统一社会信用代码" v-model="phone" @keyup.enter='log_d()' @keyup="keyu">
      <input type="password" placeholder="密码" v-model="password" @keyup="keyu" @keyup.enter='log_d()'>
      <div class="log_no" v-if="log_no" @click='log_d()'>立即登录</div>
      <div class="log" v-if="log" @click="log_d" >立即登录</div>
      <span class="span" @click="jin_wang">忘记密码</span>
    </div>
  </div>
</template>
<script>
import { ent_acct_login } from '../api/api.js'
// import heade from '../components/Heade'
import zongqiu from '../components/Zonganniu'
export default {
  name: 'enterprise',
  data () {
    return {
      log: false,
      log_no: true,
      phone: '',
      password: ''
    }
  },
  // heade,
  components: { zongqiu },
  mounted () {
    this.jichu()
  },
  created () {
    // let that=this
    // document.onkeydown=function(e){
    //   let key=window.event.keyCode;
    //   if(key==13){
    //     that.log_d();
    //   }
    // }
  },
  methods: {
    // 刚开始的基础信息
    jichu () {
      if (sessionStorage.getItem('pc_mlbb_phone') != undefined) {
        this.phone = JSON.parse(sessionStorage.getItem('pc_mlbb_phone'))
      }
    },
    home () {
      this.$router.push('/')
    },
    // 显示与隐藏
    keyu () {
      if (this.password.length >= 6 && this.phone.length >= 6) {
        this.log = true
        this.log_no = false
      } else {
        this.log_no = true
        this.log = false
      }
    },
    // 点击登录
    log_d () {
      if (this.log) {
        ent_acct_login({
          data: {
            ent_acct: this.phone,
            ent_pwd: this.password
          }
        }).then(res => {
          console.log(res)
          if (res.data.code == 10017) {
            this.$message({
              message: '登录成功',
              type: 'success'
            })
            this.$router.push('/master')
            sessionStorage.setItem('pc_ent_id', JSON.stringify(res.data.body.data))
          } else if (res.data.code == 10088) { // 第一次登陆需要修改密码
            this.$message({
              message: '登录成功,进入修改密码页',
              type: 'success'
            })
            sessionStorage.setItem('pc_mlbb_phone', JSON.stringify(this.phone))
            this.$router.push('/resetpwd')
          } else if (res.data.code == 10036) {
            this.$message({
              message: '密码错误',
              type: 'error'
            })
          }
        })
      } else {
        this.$message({
          message: '请查看您写的信息是否正确',
          type: 'warning'
        })
      }
    },
    // 进入忘记密码页
    jin_wang () {
      this.$router.push('/fgtrgs')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.enterprise {
  background-image: url("../assets/wai_daohang/qiye_background.png");
  // height: 100vh;
  // background-position: center;
  // background-repeat: no-repeat;
  background-size:100% 100%;
  position:fixed;
  // background-position: center 0;
  height: 100vh;
  width:100vw;
  background-repeat: no-repeat;
  position: relative;
  .xuanfu{
    position: absolute;
    top:4.3rem;
    left:1.5rem;
    z-index: 100;
  }
  // .header {
  //   position: fixed;
  //   top: 0;
  //   height: 0.8rem;
  //   width: 100%;
  //   background-color: #f5f5f5;
  //   .center_title {
  //     margin: 0 auto;
  //     height: 100%;
  //     width: 9.55rem;
  //     background-color: #f5f5f5;
  //     display: flex;
  //     justify-content: space-between;
  //     align-items: center;
  //     .header_left {
  //       display: flex;
  //       align-items: center;
  //       .logo {
  //         height: 0.33rem;
  //         width: 0.35rem;
  //       }
  //       .text {
  //         height: 0.18rem;
  //         width: 0.75rem;
  //         margin-left: 0.05rem;
  //         margin-right: 0.23rem;
  //       }
  //     }
  //     .header_right {
  //       display: flex;
  //       align-items: center;
  //       .phone {
  //         height: 0.18rem;
  //         width: 0.17rem;
  //         margin-right: 0.05rem;
  //       }
  //       .phone_text {
  //         font-size: 0.14rem;
  //         color: #3c3a3e;
  //       }
  //     }
  //   }
  //   @media screen and (max-width: 1000px) {
  //     .center_title {
  //       width: 90%;
  //     }
  //   }
  // }
  .box {
    // position: absolute;
    // height: 3.8rem;
    // width: 330px;
    // background: rgba(255, 255, 255, 0.4);
    // box-shadow: 0 0.03rem 0.15rem 0 rgba(0, 0, 0, 0.35);
    // border-radius: 0.06rem;
    // top: 25%;
    // left: 60%;
    // text-align: center;
    position: absolute;
    height: 3.89rem;
    width:4.11rem;
    right:3rem;
    top:2.84rem;
    padding: 0.43rem 0.37rem;
    background:  url("../assets/wai_daohang/kuang_denglu.png");
    background-repeat: no-repeat;
    background-size:100% 100% ;
    // .beijing {
    //   content: "";
    //   background:  url("../assets/wai_daohang/kuang_denglu.png");
    //   background-repeat: no-repeat;
    //   background-size:100% 100% ;
    //   opacity: 0.6;
    //   top: 0;
    //   left: 0;
    //   bottom: 0;
    //   right: 0;
    //   position: absolute;
    //   z-index: 0;
    // }
    // 43  27
    p {
      font-size: 0.27rem;
      color: #bb8dff;
      font-weight: 500;
      // margin-top: 0.14rem;
      // margin-bottom: 0.55rem;
    }
    input {
      height: 0.58rem;
      width: 100%;
      // border-radius: 0.06rem;
      border:none;
      border-bottom: 1px solid #9768d6;
      margin-bottom: 0.25rem;
      // text-align: center;
      z-index: 2;
      color:#bb8dff;
      font-size: 0.12rem;
      background-color:transparent;
      outline:none;
    }
    input::-webkit-input-placeholder { /* WebKit browsers */ 

      color: #bb8dff; 

    } 

    input:-moz-placeholder { /* Mozilla Firefox 4 to 18 */ 

      color: #bb8dff; 

    } 

    input::-moz-placeholder { /* Mozilla Firefox 19+ */ 

      color: #bb8dff; 

    } 

    input:-ms-input-placeholder { /* Internet Explorer 10+ */ 

      color: #bb8dff; 

    } 
    .log {
      display: block;
      height: 0.58rem;
      width: 100%;
      cursor:pointer;
      // background: -webkit-linear-gradient(
      //   left,
      //   #e65050,
      //   #fa6f6f
      // ); /* Safari 5.1 - 6.0 */
      // background: -o-linear-gradient(
      //   right,
      //   #e65050,
      //   #fa6f6f
      // ); /* Opera 11.1 - 12.0 */
      // background: -moz-linear-gradient(
      //   right,
      //   #e65050,
      //   #fa6f6f
      // ); /* Firefox 3.6 - 15 */
      // background: linear-gradient(to right, #e65050, #fa6f6f); /* 标准的语法 */
      background: #8d52e3;
      line-height: 0.58rem;
      text-align: center;
      border-radius: 0.06rem;
      color: #fff;
      box-shadow: 0 0.02rem 0.04rem 0 rgba(0, 0, 0, 0.5);
      font-size: 0.2rem;
      margin: 0 auto;
      margin-top: 0.24rem;
    }
    .log_no {
      display: block;
      height: 0.58rem;
      width: 100%;
      background-color: #8d52e3;
      line-height: 0.58rem;
      text-align: center;
      border-radius: 0.06rem;
      color: #fff;
      cursor:pointer;
      box-shadow: 0 0.02rem 0.04rem 0 rgba(0, 0, 0, 0.5);
      font-size: 0.2rem;
      margin: 0 auto;
      margin-top: 0.24rem;
    }
    .span{
      display: block;
      font-size: 0.18rem;
      color:#bb8dff;
      text-align: center;
      margin-top:0.24rem;
      cursor: pointer;
    }
  }
  
}
// @media screen and (max-width: 500px) {
//   .enterprise {
//     background-size: 320%;
//   }
// }
@media screen and (max-height: 690px) {
    .enterprise {
      height:690px;
    }
}
@media screen and (max-width: 1280px) {
    .enterprise {
      width:1280px;
    }
}
// @media screen and (max-width: 9rem) {
//   .box {
//     position: absolute;
//     left:50%;
//     margin-left: -2rem;
//   }
// }
</style>
