<!--
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-09-10 09:43:36
 * @LastEditTime: 2019-09-29 14:49:19
 * @LastEditors: Please set LastEditors
 -->
<template>
    <div class="zonganniu">
        <div class="home_tit_qiu">
          <div class="tuozhuai_qiu"></div>
          <img class="img1"  @click="dianji_hui" src="../assets/wai_daohang/logo_wai.png" alt="">
          <div class="wrapper" data-anim="base wrapper">
            <div class="circle" data-anim="base left"></div>
            <div class="circle" data-anim="base right"></div>
          </div>
          <div class="wrapper2" data-anim="base2 wrapper2">
            <div class="circle2" data-anim="base2 left2"></div>
            <div class="circle2" data-anim="base2 right2"></div>
          </div>
          <div class="wrapper3" data-anim="base3 wrapper3">
            <div class="circle3" data-anim="base3 left3"></div>
            <div class="circle3" data-anim="base3 right3"></div>
          </div>
          <p class="home_tit_p1 wow bounceIn" @click="jin_shezhi" data-wow-delay="0.4s">
            <img src="../assets/wai_daohang/houtai.png" alt="">
            <span>后台设置</span>
          </p>
          <p class="home_tit_p2 wow bounceIn" @click.stop @click="dakai_qiye_list" data-wow-delay="0.6s">
            <img src="../assets/wai_daohang/qiye.png" alt="">
            <span>企业切换</span>
            <!--  v-if="qiye_list" -->
            <ul v-if="qiye_list" @click.stop>
              <li v-for="(i,index) in qiyes_list" :key='index' @click="qiehuan_qiye(i)">{{i.ent_name}}</li>
            </ul>
          </p>
          <p class="home_tit_p3 wow bounceIn" @click="jin_guanli" data-wow-delay="0.8s">
            <img src="../assets/wai_daohang/shuju.png" alt="">
            <span>数据管理</span>
          </p>
          <p class="home_tit_p4 wow bounceIn" @click="jin_zhongxin" data-wow-delay="1s">
            <s v-if="Number(xiaoxi_num)!=0">{{xiaoxi_num}}</s>
            <img src="../assets/wai_daohang/shenpi.png" alt="">
            <span>审批中心</span>
          </p>
          <p class="home_tit_p5 wow bounceIn" data-wow-delay="1.2s">
            <img src="../assets/wai_daohang/xiaoxi.png" alt="">
            <span>消息中心</span>
          </p>
          <p class="home_tit_p6 wow bounceIn" data-wow-delay="1.4s">
            <img src="../assets/wai_daohang/kehu.png" alt="">
            <span>客户服务</span>
          </p>
          <p class="home_tit_p7 wow bounceIn" @click="out" data-wow-delay="1.6s">
            <img src="../assets/wai_daohang/tuichu.png" alt="">
            <span>退出登录</span>
          </p>
        </div>
    </div>
</template>

<script>
import { WOW } from 'wowjs'
import { insert_m_login_log, query_notify_num, query_aim_module_power_list, get_my_info, logout, toggle_user_ent, get_rel_list } from '../api/api.js'
import { query_user_ent_list } from '../api/noapi.js'
export default {
  name: 'zonganniu',

  data () {
    return {
      gongsi_name: '',
      showa: false,
      jichuxinxi: '',
      datea: '',
      qiye_list: false,
      qiyes_list: '',
      jichuxinxi: '',
      name: '智',
      shifou_jin_mubiao: false,
      xiaoxi_num: '0'
    }
  },
  created () {
    if (sessionStorage.getItem('pc_mlbb_jichuxinxi') != null && sessionStorage.getItem('pc_mlbb_jichuxinxi') != undefined && sessionStorage.getItem('pc_mlbb_jichuxinxi') != '') {
      this.jichuxinxi = JSON.parse(JSON.parse(sessionStorage.getItem('pc_mlbb_jichuxinxi')))
      console.log(999999999, this.jichuxinxi)
    }
    this.jichu()
  },
  mounted () {
    new WOW().init()
  },
  watch: {
  },
  methods: {
    // 基础
    jichu () {
      // this.quanxian=this.$func.quanxian()
      // if(this.quanxian!=='0'){
      //   this.quanxian.map(item=>{this['module_'+item.module_id]=true})
      // }
      this.jichuxinxi = this.$jichuxinxi()
      if (sessionStorage.getItem('pc_mlbb_ent_flag') != null && sessionStorage.getItem('pc_mlbb_ent_flag') != undefined && sessionStorage.getItem('pc_mlbb_ent_flag') != '') {
        this.datea = JSON.parse(sessionStorage.getItem('pc_mlbb_ent_flag'))
      }
      if (this.datea == 1) {
        this.gongsi_name = JSON.parse(sessionStorage.getItem('pc_mlbb_ent_info')).ent_name
      } else {
        this.gongsi_name = ''
      }
      if (this.$jichuxinxi().ent_info != undefined && this.$jichuxinxi().ent_info.staff_level == 1) {
        this.shifou_jin_mubiao = true
      } else {
        this.mubiao_quanxian_jiekou()
      }
      query_notify_num({
        data: {
          ent_id: this.$ent_id(),
          user_id: this.$jichuxinxi().user_id,
          read_flag: '0'
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 200) {
          const date = JSON.parse(res.data.body.data)
          console.log(date)
          this.xiaoxi_num = Number(date) > 99 ? '99+' : date
        } else if (res.data.code == 500) {}
      })
    },
    mubiao_quanxian_jiekou () {
      query_aim_module_power_list({
        data: {
          ent_id: this.$ent_id(),
          user_id: this.$jichuxinxi().user_id
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 200) {
          const date = JSON.parse(res.data.body.data)
          console.log(date)
          if (date.length != 0) {
            this.shifou_jin_mubiao = true
            this.$bendi_cunfang_cunchu('mubiao_quanxian_list', date, '暂时')
          } else {
            this.shifou_jin_mubiao = false
          }
        } else if (res.data.code == 500) {}
      })
    },
    // dian_gongneng(){
    //   this.showa=!this.showa
    //   this.qiye_list=false
    // },
    out () {
      logout({
        data: {
          mobile: this.jichuxinxi.mobile
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 10015) {
          this.$message({
            message: '退出成功',
            type: 'success'
          })
          sessionStorage.clear()
          // sessionStorage.setItem("pc_pc_wai_daohang", JSON.stringify(0));
          this.$router.push('/')
        } else if (res.data.code == 10016) {
          this.$message({
            message: '退出失败',
            type: 'error'
          }) 
        } else if (res.data.code == 11000) {
          // this.$message({
          //     message: '您未登录',
          //     type: 'error'
          // }); 
          this.$message({
            message: '退出成功',
            type: 'success'
          })
          sessionStorage.clear()
          // sessionStorage.setItem("pc_pc_wai_daohang", JSON.stringify(0));
          this.$router.push('/')
        }
      })
    },
    // 进入设置页面
    jin_shezhi () {
      this.$router.push('/install')
    },
    jin_zhongxin () {
      console.log(this.jichuxinxi.ent_info)
      if (this.jichuxinxi.ent_info.ent_name.length != 0) {
        this.$router.push('/initiateapp')
      } else {
        this.$message({
          message: '您当前没有企业',
          type: 'warning'
        }) 
      }
    },
    // 进数据管理页面
    jin_guanli () {
      if (this.jichuxinxi.ent_info.ent_name.length != 0) {
        // this.$router.push('/dbms')
      } else {
        this.$message({
          message: '您当前没有企业',
          type: 'warning'
        }) 
      }
    },
    // 打开企业列表
    dakai_qiye_list () {
      this.qiye_list = !this.qiye_list
      query_user_ent_list({
        data: {
          user_id: this.jichuxinxi.user_id
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 10080) {
          console.log(JSON.parse(res.data.body.data))
          const date = JSON.parse(res.data.body.data)
          this.qiyes_list = date
          console.log('qiyes_list', date)
        } else if (res.data.code == 10081) {
        }
      })
    },
    // 点击切换企业
    qiehuan_qiye (i) {
      if (i.use_status != 0) {
        if (i.node_flag != 0) {
          if (i.node_not_enough != 0) {
            toggle_user_ent({
              data: {
                ent_id: i.ent_id,
                user_id: this.jichuxinxi.user_id
              }
            }).then(res => {
              console.log(res)
              if (res.data.code == 10082) {
                this.$message({
                  message: '切换成功',
                  type: 'success'
                })
                get_my_info({
                  data: {
                    user_id: this.$jichuxinxi().user_id
                  }
                }).then(ras => {
                  if (ras.data.code == 10069) {
                    this.jichuxinxi = JSON.parse(ras.data.body.data)
                    sessionStorage.setItem('pc_mlbb_jichuxinxi', JSON.stringify(ras.data.body.data))
                    console.log(this.jichuxinxi)
                    this.$router.push('/home')
                    this.qiye_list = false
                  }
                })
                get_rel_list({
                  data: {
                    user_id: this.jichuxinxi.user_id
                  }
                }).then(ras => {
                  if (ras.data.code == 10091) {
                    console.log(JSON.parse(ras.data.body.data))
                    const qiye = JSON.parse(ras.data.body.data)
                    sessionStorage.setItem('pc_mlbb_ent_flag', JSON.stringify(qiye.ent_flag))
                    sessionStorage.setItem('pc_mlbb_ent_info', JSON.stringify(qiye.staff_info))
                    // sessionStorage.setItem("pc_mlbb_quanxian", JSON.stringify(qiye.rel_list));
                    // this.jichu()
                  } else if (ras.data.code == 10092) {}
                })
                insert_m_login_log({
                  data: {
                    ent_id: i.ent_id,
                    mobile: this.jichuxinxi.mobile
                  }
                })
              } else if (res.data.code == 10083) {
                this.$message({
                  message: '切换失败',
                  type: 'error'
                })
              } else if (res.data.code == 11700) {
                this.$message({
                  message: '您没有该企业的登录权限，请联系主管理员添加',
                  type: 'error'
                })
              }
            })
          } else {
            this.$message({
              message: '当前登录节点少于付费节点数，请联系主管理员进行设置',
              type: 'error'
            })
          }
        } else {
          this.$message({
            message: '您没有该企业的登录权限，请联系主管理员添加',
            type: 'error'
          })
        }
      } else {
        this.$message({
          message: '该企业暂不能使用，请联系官方客服',
          type: 'error'
        })
      }
    },
    // 进入目标模块
    jin_mubiao () {
      if (this.jichuxinxi.ent_info.ent_name.length != 0) {
        this.$router.push('/aspreackeven')
      } else {
        this.$message({
          message: '您当前没有企业',
          type: 'warning'
        }) 
      }
    },
    // 进入销售模块
    jin_xiaoshou () {
      if (this.jichuxinxi.ent_info.ent_name.length != 0) {
        this.$router.push('/sale')
      } else {
        this.$message({
          message: '您当前没有企业',
          type: 'warning'
        }) 
      }
    },
    // 打开共有审批
    dakai_shenpi () {
      if (this.jichuxinxi.ent_info.ent_name.length != 0) {
        this.$router.push('/biaodangg')
      } else {
        this.$message({
          message: '您当前没有企业',
          type: 'warning'
        }) 
      }
    },
    jin_wode () {
      this.$router.push('/me')
    },
    dianji_hui () {
      this.$router.push('/home')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.home_tit_qiu{
  width:1.1rem;
  height:1.1rem;
  border-radius: 50%;
  // position: relative;
  // top:4.3rem;
  // left:1.5rem;
  z-index: 10;
  .tuozhuai_qiu{
    position: absolute;
    top:-0.1rem;
    left: -0.1rem;
    height:0.25rem;
    width:0.25rem;
    background: cornflowerblue;
    cursor: move;
    border-radius: 50%;
    z-index: 100;
  }
  @keyframes scaleDraw {
    /*定义关键帧、scaleDrew是需要绑定到选择器的关键帧名称*/
    0% {
      transform: scale(1); /*开始为原始大小*/
    }
    25% {
      transform: scale(1.1); /*放大1.1倍*/
    }
    50% {
      transform: scale(1);
    }
    75% {
      transform: scale(1.1);
    }
  }
  .img1 {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -0.4rem;
    margin-top: -0.4rem;
    width: 0.8rem;
    height: 0.8rem;
    border-radius: 50%;
    // box-shadow: 0 0 0.5rem rgb(250, 157, 157);
    -webkit-animation-name: scaleDraw; /*关键帧名称*/
    -webkit-animation-timing-function: ease-in-out; /*动画的速度曲线*/
    -webkit-animation-iteration-count: infinite; /*动画播放的次数*/
    -webkit-animation-duration: 5s; /*动画所花费的时间*/
    cursor: pointer;
    z-index: 100;
  }
  &:hover{
    .wrapper{
      display: block;
    }
    .circle{
      display: block;
    }
    .wrapper2{
      display: block;
    }
    .circle2{
      display: block;
    }
    .wrapper3{
      display: block;
    }
    .circle3{
      display: block;
    }
    .home_tit_p1{
      display: block;
    }
    .home_tit_p2{
      display: block;
    }
    .home_tit_p3{
      display: block;
    }
    .home_tit_p4{
      display: block;
    }
    .home_tit_p5{
      display: block;
    }
    .home_tit_p6{
      display: block;
    }
    .home_tit_p7{
      display: block;
    }
  }
  .wrapper {
    width: 2.5rem; /* Set the size of the progress bar */
    height: 2.5rem;
    position: absolute; /* Enable clipping */
    left: 50%;
    top:50%;
    display: none;
    margin-left: -1.25rem;
    margin-top: -1.25rem;
    clip: rect(0, 2.5rem, 2.5rem, 1.25rem); /* Hide half of the progress bar */
  }
  /* Set the sizes of the elements that make up the progress bar */
  .circle {
    width: 2.48rem;
    height: 2.48rem;
    border: 0.01rem solid #6c44a6;
    border-radius: 50%;
    position: absolute;
    display: none;
    clip: rect(0, 1.25rem, 2.5rem, 0);
  }
  .wrapper2 {
    width: 4rem; /* Set the size of the progress bar */
    height: 4rem;
    position: absolute; /* Enable clipping */
    left: 50%;
    top:50%;
    display: none;
    margin-left: -2rem;
    margin-top: -2rem;
    clip: rect(0, 4rem, 4rem, 2rem); /* Hide half of the progress bar */
  }
  /* Set the sizes of the elements that make up the progress bar */
  .circle2 {
    width: 2.6rem;
    height: 2.6rem;
    border: 0.7rem solid #5c3095;
    border-radius: 50%;
    position: absolute;
    display: none;
    clip: rect(0, 2rem, 4rem, 0);
  }
  .wrapper3 {
    width: 4.2rem; /* Set the size of the progress bar */
    height: 4.2rem;
    position: absolute; /* Enable clipping */
    left: 50%;
    top:50%;
    display: none;
    margin-left: -2.1rem;
    margin-top: -2.1rem;
    clip: rect(0, 4.2rem, 4.2rem, 2.1rem); /* Hide half of the progress bar */
  }
  /* Set the sizes of the elements that make up the progress bar */
  .circle3 {
    width: 4.18rem;
    height: 4.18rem;
    border: 0.01rem solid #5c3095;
    border-radius: 50%;
    position: absolute;
    display: none;
    clip: rect(0, 2.1rem, 4.2rem, 0);
  }
  div[data-anim~=base],div[data-anim~=base2],div[data-anim~=base3] {
    -webkit-animation-iteration-count: 1;  /* Only run once */
    -webkit-animation-fill-mode: forwards; /* Hold the last keyframe */
    -webkit-animation-timing-function:linear; /* Linear animation */
  }

  .wrapper[data-anim~=wrapper],.wrapper2[data-anim~=wrapper2],.wrapper3[data-anim~=wrapper3] {
    -webkit-animation-duration: 0.01s; /* Complete keyframes asap */
    -webkit-animation-delay: 0.5s; /* Wait half of the animation */
    -webkit-animation-name: close-wrapper; /* Keyframes name */
  }

  .circle[data-anim~=left],.circle2[data-anim~=left2],.circle3[data-anim~=left3] {
    -webkit-animation-duration: 1s; /* Full animation time */
    -webkit-animation-name: left-spin;
  }

  .circle[data-anim~=right],.circle2[data-anim~=right2],.circle3[data-anim~=right3] {
    -webkit-animation-duration: 0.5s; /* Half animation time */
    -webkit-animation-name: right-spin;
  }
  /* Rotate the right side of the progress bar from 0 to 180 degrees */
  @-webkit-keyframes right-spin {
    from {
      -webkit-transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(180deg);
    }
  }
  /* Rotate the left side of the progress bar from 0 to 360 degrees */
  @-webkit-keyframes left-spin {
    from {
      -webkit-transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
    }
  }
  /* Set the wrapper clip to auto, effectively removing the clip */
  @-webkit-keyframes close-wrapper {
    to {
      clip: rect(auto, auto, auto, auto);
    }
  }
  .home_tit_p1{
    position: absolute;
    height:0.42rem;
    width:0.42rem;
    right:-1.16rem;
    top:-0.3rem;
    display: none;
    img{
      height:0.42rem;
      width:0.42rem;
      display: block;
    }
    span{
      font-size: 0.2rem;
      color:#fff;
      display: none;
    }
    &:hover{
      img{
        display: none;
      }
      span{
        display: block;
      }
    }
    cursor: pointer;
  }
  .home_tit_p2{
    position: absolute;
    right:-1.3rem;
    height:0.42rem;
    width:0.42rem;
    top: 0.26rem;
    display: none;
    img{
      height:0.42rem;
      width:0.42rem;
      display: block;
    }
    span{
      font-size: 0.2rem;
      color:#fff;
      display: none;
    }
    &:hover{
      img{
        display: none;
      }
      span{
        display: block;
      }
    }
    ul{
      height:1.2rem;
      width:2rem;
      background:#fff;
      position: absolute;
      border-radius: 0.2rem;
      left:0.29rem;
      top: 0.34rem;
      padding: 0.2rem 0.1rem;
      opacity: 0.8;
      overflow-y:auto;
      &::-webkit-scrollbar {
          width: 0.2rem;
          height: 0.08rem;
      }
      
      &::-webkit-scrollbar-thumb {
          border-radius: 0.12rem;
          border: 0.06rem solid rgba(0, 0, 0, 0);
          box-shadow: 0.08rem 0 0 #A5ADB7 inset;
      }
      
      &::-webkit-scrollbar-thumb:hover {
          box-shadow: 0.08rem 0 0 #4A4A4A inset;
      }
      li{
        text-align: center;
        color:#1a2533;
        font-size: 0.14rem;
        line-height: 0.3rem;
        &:hover{
          background: #eee;
        }
      }
    }
    cursor: pointer;
  }
  .home_tit_p3{
    position: absolute;
    right:-1.18rem;
    height:0.42rem;
    width:0.42rem;
    top: 0.82rem;
    display: none;
    img{
      height:0.42rem;
      width:0.42rem;
      display: block;
    }
    span{
      font-size: 0.2rem;
      color:#fff;
      display: none;
    }
    &:hover{
      img{
        display: none;
      }
      span{
        display: block;
      }
    }
    cursor: pointer;
  }
  .home_tit_p4{
    position: absolute;
    right:-0.9rem;
    height:0.42rem;
    width:0.42rem;
    top: 1.45rem;
    display: none;
    img{
      height:0.42rem;
      width:0.42rem;
      display: block;
    }
    s{
      display: inline-block;
      position: absolute;
      right: -0.1rem;
      color:#fff;
      top:-0.1rem;
      height:0.25rem;
      width:0.25rem;
      text-align: center;
      line-height: 0.25rem;
      background: #f96c6c;
      border-radius: 50%;
    }
    span{
      font-size: 0.2rem;
      color:#fff;
      display: none;
    }
    &:hover{
      img{
        display: none;
      }
      span{
        display: block;
      }
    }
    cursor: pointer;
  }
  .home_tit_p5{
    position: absolute;
    right:-0.36rem;
    height:0.42rem;
    width:0.42rem;
    top: 1.85rem;
    display: none;
    img{
      height:0.42rem;
      width:0.42rem;
      display: block;
    }
    span{
      font-size: 0.2rem;
      color:#fff;
      display: none;
    }
    &:hover{
      img{
        display: none;
      }
      span{
        display: block;
      }
    }
    cursor: pointer;
  }
  .home_tit_p6{
    position: absolute;
    right:0.24rem;
    height:0.42rem;
    width:0.42rem;
    top: 1.96rem;
    display: none;
    img{
      height:0.42rem;
      width:0.42rem;
      display: block;
    }
    span{
      font-size: 0.2rem;
      color:#fff;
      display: none;
    }
    &:hover{
      img{
        display: none;
      }
      span{
        display: block;
      }
    }
    cursor: pointer;
  }
  .home_tit_p7{
    position: absolute;
    top:1.86rem;
    left:-0.21rem;
    height:0.42rem;
    width:0.42rem;
    display: none;
    img{
      height:0.42rem;
      width:0.42rem;
      display: block;
    }
    span{
      font-size: 0.2rem;
      color:#fff;
      display: none;
    }
    &:hover{
      img{
        display: none;
      }
      span{
        display: block;
      }
    }
    cursor: pointer;
  }
}
</style>
